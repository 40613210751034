import React from "react";

import { ScrollingProvider } from "../../context/ScrollingContext";

import SectionHead from "../../components/ui/SectionHead";
import Container from "../../components/ui/Container";
import CourseCard from "../../components/ui/CourseCard";

// import year1 from "../../../assets/year-2.jpeg";
import year2 from "../../assets/year2.png";
import year3 from "../../assets/year3.png";
// import year1 from "../../assets/year-1.jpeg";
import Waves from "../../components/ui/Waves";
import { ReactComponent as Headline } from "../../assets/HeadLine.svg";

const YearsSection = ({ title = false }) => {
    // const { token } = useContext(AuthContext);
    return (
        <div className="relative mb-20 overflow-hidden " id="courses">
            <h1 className="text-3xl my-10 pt-10 lg:text-5xl font-vip text-center group text-[#303506] dark:text-[#C3D14F]">
                <span className="clr-text-primary smooth smooth group-hover:text-Olive-500 dark:hover:text-Olive-200">
                    نظمتهالك{" "}
                </span>{" "}
                <span className="text-Olive-500 smooth group-hover:text-black dark:group-hover:text-white smooth">
                    يا صديقي
                </span>
            </h1>
            <div className=" mx-auto w-1/2 md:w-3/4 h-full">
                <Headline />
            </div>

            <Container>
                <div
                    className={`g-teal-400 smooth mt-10 clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 flex flex-wrap justify-center items-center mx-auto gap-10 md:gap-8 lg:gap-6`}
                >
                    {/* <CourseCard
                        name="الصف الدراسي الأول"
                        description="جميع كورسات الصف الأول الثانوي"
                        isYear={1}
                        isStaticPicture={year1}
                    /> */}
                    <CourseCard
                        name="الصف الدراسي الثاني"
                        description="جميع كورسات الصف الثاني الثانوي"
                        isYear={2}
                        isStaticPicture={year2}
                    />
                    <CourseCard
                        name="الصف الدراسي الثالث"
                        description="جميع كورسات الصف الثالث الثانوي"
                        isYear={3}
                        isStaticPicture={year3}
                    />
                </div>
            </Container>

            {/* <Waves className="fill-yellow-200" /> */}
            {/* <div className="py-16 bg-yellow-200"></div> */}
        </div>
    );
};

export default YearsSection;
