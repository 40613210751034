import React from "react";
import { useParams } from "react-router-dom";
import MainSection from "../sections/home/MainSection";
import ProfileSection from "../sections/home/ProfileSection";
import Wise1Section from "../sections/home/Wise1Section";
import Wise2Section from "../sections/home/Wise2Section";
import { ScrollingProvider } from "../context/ScrollingContext";
import { getYearPlaceHolder } from "../services/yearSevices";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";
import auth from "../services/authServices";
import Categorie from "../sections/home/CoursesSection/Categorie";
import AboutSection from "../sections/home/AboutSection";
import { ReactComponent as Headline } from "../assets/HeadLine.svg";

const Year = () => {
    const { id } = useParams();
    const token = auth.getToken();

    return (
        <>
            {token ? (
                <>
                    {/* <ScrollingProvider>
                        <SectionHead title={"اشتراكاتك"} />
                    </ScrollingProvider> */}
                    <h1 className="text-3xl my-10 pt-10 lg:text-5xl font-vip text-center group text-[#303506] dark:text-[#C3D14F]">
                        <span className="clr-text-primary smooth smooth group-hover:text-Olive-500 dark:hover:text-Olive-200">
                            إشتراكاتك{" "}
                        </span>{" "}
                        <span className="text-Olive-500 smooth group-hover:text-black dark:group-hover:text-white smooth">
                            يا صديقي
                        </span>
                    </h1>
                    <div className=" mx-auto w-1/2 md:w-3/4 h-full">
                        <Headline />
                    </div>

                    <Categorie
                        api={`/api/sellables/subscribed`}
                        noCoursesPlaceholder="انت غير مشترك بأي كورس حتى الآن!"
                    />
                </>
            ) : (
                <>
                    <ScrollingProvider>
                        <MainSection title={getYearPlaceHolder(id)} />
                        <AboutSection />
                    </ScrollingProvider>
                </>
            )}

            <CoursesSection title={getYearPlaceHolder(id)} year={id} />
            {/* <ScrollingProvider>
                <Wise2Section />
            </ScrollingProvider> */}
        </>
    );
};

export default Year;
