import React, { useContext } from "react";

import { ScrollingProvider } from "../context/ScrollingContext";

import MainSection from "../sections/home/MainSection";
import AboutSection from "../sections/home/AboutSection";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";
import AwardsSecction from "../sections/home/AwardsSecction";
import YearsSection from "../sections/home/YearsSection";
import { useNavigate } from "react-router-dom";
import auth from "../services/authServices";
import AuthContext from "../context/AuthContext";
const Home = () => {
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();
    const token = auth.getToken();
    if (token) {
        if (user.year) {
            navigate(`/years/${user.year}`);
        }
    }
    return (
        <>
            <ScrollingProvider>
                <MainSection />
                <AboutSection />
            </ScrollingProvider>
            <AwardsSecction />
            <YearsSection />
            {/* <CoursesSection /> */}
        </>
    );
};

export default Home;
