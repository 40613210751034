import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import http from "../../../services/httpServices";

import Container from "../../../components/ui/Container";
import LoadingIcon from "../../../components/ui/LoadingIcon";
import SideTitle from "../../../components/ui/SideTitle";
import CourseCard from "../../../components/ui/CourseCard";
import auth from "../../../services/authServices";
import { ReactComponent as Headline } from "../../../assets/HeadLine.svg";
import MagdyCourseCard from "../../../components/ui/MagdyCourseCard";
const Categorie = ({
    titleFirst = false,
    titleLast,
    id = 1,
    count = "",
    api = `/api/categories/${id}/sellables`,
    className = "",
    grid = "normal",
    noCoursesPlaceholder = `سيتم إضافه ${
        titleFirst ? `${titleFirst} ${titleLast}` : "الكورسات"
    } قريبا`,
    coursesList = false,
    isPrepaid = false,
    onPrepaidClick = null,
}) => {
    const [courses, setCourses] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    if (!className.includes("space-x")) {
        if (grid === "normal") {
            className += ` grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10`;
        } else {
            className += ` grid-cols-1 lg:grid-cols-2 gap-5`;
        }
    }

    const [placeholder, setPlaceholder] = useState(
        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
            <span className="flex-center-both space-x-3 space-x-reverse">
                <LoadingIcon className={"font-h1 text-teal-600 dark:text-teal-400"} />
                <span>يتم الآن تحميل الكورسات</span>
            </span>
        </div>
    );
    const getCourses = async (getAll = false) => {
        try {
            setIsLoading(true);
            if (!showAll) {
                const token = auth.getToken();
                const config = auth.getAuthConfig(token);
                if (count) {
                    count = getAll ? "all" : count;
                }
                const link = count ? `${api}/${count}` : api;
                const { data } = await http.get(link, config);

                setCourses([]);
                setCourses(data);
                getAll && setShowAll(true);
            }
            setIsLoading(false);
            setPlaceholder(
                <div className="font-h2 col-span-1 mx-auto md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-Olive-600 dark:text-Olive-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        } catch (error) {
            setPlaceholder(
                <div className="font-h2 col-span-1 mx-auto md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-Olive-600 dark:text-Olive-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        if (coursesList) {
            setCourses(coursesList);
            setPlaceholder(
                <div className="font-h2 col-span-1 mx-auto md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-Olive-600 dark:text-Olive-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        } else {
            getCourses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursesList]);
    return (
        <Container>
            {titleFirst ? (
                <div className="mx-auto flex-center-both">
                    <div
                        onClick={() => getCourses(true)}
                        className="cursor-pointer  w-max flex-center-both flex-col space-y-3 "
                    >
                        {/* <SideTitle first={titleFirst} last={titleLast} /> */}
                        <h1 className="text-3xl  lg:text-5xl font-vip text-center group text-[#303506] dark:text-[#C3D14F]">
                            <span className="clr-text-primary smooth smooth group-hover:text-Olive-500 dark:hover:text-Olive-200">
                                {titleFirst}
                            </span>{" "}
                            <span className="text-Olive-500 smooth group-hover:text-black dark:group-hover:text-white smooth">
                                {titleLast}
                            </span>
                        </h1>
                        <span className="w-[250px] md:w-3/4 h-full">
                            <Headline />
                        </span>
                        {isLoading ? <LoadingIcon className="text-4xl text-Olive-500 m-5" /> : null}{" "}
                    </div>
                </div>
            ) : (
                ""
            )}

            <div
                className={`g-teal-400 smooth clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 place-items-center md:place-items-start grid ${className}`}
            >
                {courses.length < 1 && placeholder}
                {courses.length > 0 &&
                    courses.map((course) => {
                        return (
                            <MagdyCourseCard
                                key={course.id}
                                {...course}
                                isPrepaid={isPrepaid}
                                onPrepaidClick={onPrepaidClick}
                            />
                        );
                    })}
            </div>
        </Container>
    );
};

export default Categorie;
